
document.addEventListener('DOMContentLoaded', () => {
    const cells = document.querySelectorAll('.cell');
    const winningCombos = [
        [0, 1, 2],
        [3, 4, 5],
        [6, 7, 8],
        [0, 3, 6],
        [1, 4, 7],
        [2, 5, 8],
        [0, 4, 8],
        [2, 4, 6]
    ];
    let currentPlayer = 'X';
    let gameOver = false;   

    function init() {
        cells.forEach(cell => {
            cell.textContent = '';
            cell.classList.remove('X', 'O');
            cell.addEventListener('click', cellClick, { once: true });
        });
        currentPlayer = 'X';
        gameOver = false;
        resetMessages();
    }

    function resetMessages() {
        document.querySelector('.win').style.zIndex = '-1';
        document.querySelector('.lost').style.zIndex = '-1';
        document.querySelector('.empate').style.zIndex = '-1';
    }

    cells.forEach(cell => {
        cell.addEventListener('click', cellClick, { once: true });
    });

    document.getElementById('play-again-link').addEventListener('click', (e) => {
        e.preventDefault();
        init();
    });

    function cellClick(e) {
        if (gameOver) return;

        const cell = e.target;
        cell.textContent = currentPlayer;
        cell.classList.add(currentPlayer);

        if (checkWin(currentPlayer)) {
            showEndMessage(currentPlayer === 'X' ? 'win' : 'lost');
            gameOver = true;
        } else if (isDraw()) {
            showEndMessage('empate');
            gameOver = true;
        } else {
            currentPlayer = currentPlayer === 'X' ? 'O' : 'X';
            if (currentPlayer === 'O') {
                setTimeout(machineMove, 500);
            }
        }
    }

    function machineMove() {
        const emptyCells = Array.from(cells).filter(cell => !cell.textContent);
        if (emptyCells.length === 0) return;

        let move;
        for (let combo of winningCombos) {
            let userMoves = combo.filter(index => cells[index].textContent === 'X');
            let emptyMoves = combo.filter(index => !cells[index].textContent);
            if (userMoves.length === 2 && emptyMoves.length === 1) {
                move = cells[emptyMoves[0]];
                break;
            }
        }
        if (!move) {
            move = emptyCells[Math.floor(Math.random() * emptyCells.length)];
        }
        move.click();
    }

    function checkWin(player) {
        return winningCombos.some(combination => {
            return combination.every(index => cells[index].classList.contains(player));
        });
    }

    function isDraw() {
        return [...cells].every(cell => cell.textContent);
    }

    function showEndMessage(result) {
        document.querySelector(`.${result}`).style.zIndex = '2';
        setTimeout(() => {
            window.location.href = '/home.html';    
        }, 700);  // 3000 milisegundos = 3 segundos
    }

    init();
});
